import api from "./api";

const PaymentService = {
  getConfig: async () => {
    try {
      const response = await api.get("/payment/config");
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  createPaymentIntent: async () => {
    try {
      const response = await api.post("/payment/create-payment-intent");
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  createCheckoutSession: async (arg) => {
    try {
      const response = await api.post(
        "/subscription/create-checkout-session",
        arg
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  subscriptionStatus: async (email) => {
    try {
      console.log(`arg ${email}`)
      const response = await api.post("/subscription/is-subscribe", {email});
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  cancelSubscription: async (email) => {
    try {
      const response = await api.post("/subscription/cancel-subscription", {email});
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

export default PaymentService;
