import React from "react";
import { FaGithubSquare, FaLinkedin, FaTwitterSquare } from "react-icons/fa";
import { Link } from "react-scroll";

const Footer = () => {
  return (
    <div className="bg-black">
      <div className="max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300">
        <div>
          <h1 className="w-full text-3xl font-bold text-[#eb6d20]">
            TegoMerge.
          </h1>
          <p className="py-4">
            Your partner in optimizing Etsy operations, merging order details,
            and providing critical productivity insights. Elevate efficiency
            with us.
          </p>
          <div className="flex justify-between md:w-[75%] my-6">
            <a href="https://www.linkedin.com/in/melikhanhosdogdu/">
              <FaLinkedin size={30} />
            </a>
            <a href="https://twitter.com/m_hosdogdu">
              <FaTwitterSquare size={30} />
            </a>
            <a href="https://github.com/melikhanhosdogdu">
              <FaGithubSquare size={30} />
            </a>
          </div>
        </div>
        <div className="lg:col-span-2 flex justify-between mt-6">
          <div>
            <h6 className="font-medium text-gray-400">Solutions</h6>
            <ul>
              <li className="py-2 text-sm hover:cursor-pointer">
                <Link to="merge" smooth={true} duration={500}>
                  Merge
                </Link>
              </li>
              <li className="py-2 text-sm hover:cursor-pointer">
                <Link to="insight" smooth={true} duration={500}>
                  Insight
                </Link>
              </li>
              {/* <li className='py-2 text-sm'>Commerce</li> */}
              {/* <li className='py-2 text-sm'>Insights</li> */}
            </ul>
          </div>
          <div>
            <h6 className="font-medium text-gray-400 ">Company</h6>
            <ul>
              <li className="py-2 text-sm hover:cursor-pointer">
                {" "}
                <Link to="pricing" smooth={true} duration={500}>
                  Pricing
                </Link>
              </li>
              {/* <li className="py-2 text-sm">Documentation</li>
            <li className="py-2 text-sm">Guides</li>
            <li className="py-2 text-sm">API Status</li> */}
            </ul>
          </div>
          {/* <div>
          <h6 className="font-medium text-gray-400">Company</h6>
          <ul>
            <li className="py-2 text-sm">About</li>
            <li className="py-2 text-sm">Blog</li>
            <li className="py-2 text-sm">Jobs</li>
            <li className="py-2 text-sm">Press</li>
            <li className="py-2 text-sm">Careers</li>
          </ul>
        </div> */}
          <div>
            <h6 className="font-medium text-gray-400">Legal</h6>
            <ul>
              <li className="py-2 text-sm">
                <a href="/privact_policy">
                  Privacy Policy
                </a>
              </li>
              <li className="py-2 text-sm">
                <a href="/term_and_condition">
                  Terms & Conditions
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
