import { toast } from "react-toastify";

const snackBarError = (message) =>
  toast.error(message, {
    position: "bottom-center",
    autoClose: true,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
const snackBarWarning = (message) =>
  toast.warning(message, {
    position: "bottom-center",
    autoClose: true,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
export const notifyErrorByParameter = (param) => snackBarError(param);
export const notifyWarningByParameter = (param) => snackBarWarning(param);

export const notifyExportLabelExist = () =>
  snackBarWarning(
    "Notice: An 'Export Label' has been detected. Please review the PDF file thoroughly before proceeding to print"
  );

export const notifLabelCountAndOrderCountIsNotEqual = () =>
  snackBarError(
    "Label count and order count are not equal. The pdf merge has been canceled"
  );
