import apiX from "./_api";

const PrintAuthService = {
  register: async (registerData) => {
    const response = await apiX.post("/user/register", registerData);
    return response.data;
  },

  login: async (loginData) => {
    const response = await apiX.post("/user/login", loginData);
    return response.data;
  },
  userInfo: async () => {
    // const response = await apiX.get("/user/detail");
    return {};
    // response.data;
  },

  verify: async () => {
    // const response = await apiX.post("/user/verify");
    return true;
    // response.data;
  },

  refreshToken: async () => {
    // const response = await apiX.post("/user/refresh");
    return {};
    // response.data;
  },
};

export default PrintAuthService;
