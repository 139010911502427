import axios from "axios";

// Create an axios instance
const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:4000/api"
      : "https://tegomerge.com/api/",
});

// // Request interceptor for API calls
// api.interceptors.request.use(
//   async (config) => {
//     const token = localStorage.getItem("token");
//     if (token) {
//       config.headers = {
//         Authorization: `Bearer ${token}`,
//       };
//     }
//     return config;
//   },
//   (error) => {
//     Promise.reject(error);
//   }
// );

// // Response interceptor for API calls
// api.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async function (error) {
//     const originalRequest = error.config;
//     if (
//       error.response.status === 401 &&
//       originalRequest.url.includes("/refresh")
//     ) {
//       // If we got a 401 error when trying to refresh the token, log the user out.
//       localStorage.removeItem("token");
//       localStorage.removeItem("refreshToken");
//       return Promise.reject(error);
//     }

//     if (error.response.status === 401 && !originalRequest._retry) {
//       console.log("expired");
//       originalRequest._retry = true;
//       const refreshToken = localStorage.getItem("refreshToken");
//       return api.post("/auth/refresh", { refreshToken }).then((res) => {
//         if (res.status === 200) {
//           localStorage.setItem("token", res.data.token);
//           return api(originalRequest);
//         }
//       });
//     }
//     return Promise.reject(error);
//   }
// );

export default api;
