import {create} from 'zustand';

const useAuthStore = create(set => ({
  isAuthenticated: true,
  token: '',
  setAuth: (value) => set(() => ({ isAuthenticated: value })),
  setToken: (value) => set(() => ({ token: value })),
}));

export default useAuthStore;
