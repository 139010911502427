import React, { useState, useEffect } from "react";

import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import FileUpload from "../../../components/FileUpload";
import ButtonX from "../../../components/ButtonX";

import { mergeMultiPdfsAndDownload } from "../../../utils/PdfMergeUtils";

const MultiLabelOrderMerge = () => {
  const [loading, setLoading] = useState();
  const [mergeCount, setMergeCount] = useState([
    {
      labelFileName: null,
      orderFileName: null,
      merged: false,
      hasWarning: false,
      hasError: false,
      key: Math.random(),
    },
  ]);
  const [mergedList, setMergedList] = useState([]);

  const updateItemAtIndex = (key, value, index) => {
    setMergeCount((prevMergeCount) =>
      prevMergeCount.map((item, itemIndex) =>
        itemIndex === index ? { ...item, [key]: value } : item
      )
    );
  };

  const addMergeValue = (value, index) => {
    addNewMerge();
    setMergedList([
      ...mergedList.slice(0, index),
      value,
      ...mergedList.slice(index),
    ]);
  };

  const addNewMerge = () => {
    setMergeCount([
      ...mergeCount,
      {
        labelFileName: null,
        orderFileName: null,
        merged: false,
        hasWarning: false,
        hasError: false,
        key: Math.random(),
      },
    ]);
  };

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
    console.log(mergeCount.log);
  }, [mergeCount]);
  useEffect(() => {
    console.log(mergeCount.length);
    console.log(mergedList.length);
  }, [mergeCount, mergedList]);
  const handleMergeClick = async () => {
    setLoading(true);
    const newMergeCount = mergeCount.filter((item) => !item.hasError);

    for (let index = 0; index < newMergeCount.length; index++) {
      if (newMergeCount[index].hasWarning) {
        const element = mergedList.splice(index, 1)[0];
        mergedList.push(element);
      }
    }

    mergeMultiPdfsAndDownload(mergedList, mergeCount);
    setLoading(false);
  };

  const removeFileUpload = (indexToRemove) => {
    if (!mergeCount[indexToRemove].hasError) {
      setMergedList(mergedList.filter((_, index) => index !== indexToRemove));
    }
    setMergeCount(mergeCount.filter((_, index) => index !== indexToRemove));
  };

  return (
    <div className=" ">
      <div className="flex flex-col items-stretch space-y-4 500 w-full h-full">
        {mergeCount.map((item, index) => (
          <FileUpload
            key={item.key}
            item={item}
            index={index}
            removeFileUpload={removeFileUpload}
            onAddMergeValue={addMergeValue}
            updateItemAtIndex={updateItemAtIndex}
          />
        ))}

        <div className="flex justify-between  ml-4 mr-4">
          <ButtonX
            onClick={() => {
              addNewMerge();
            }}
            name={"Add"}
            variant={"dark-outline"}
          />

          <ButtonX
            onClick={handleMergeClick}
            name={"Download"}
            variant={"success"}
            loading={loading}
            disabled={mergedList.length === 0}
          />

          <ButtonX
            onClick={() => {
              window.location.reload();
            }}
            name={"Reset"}
            variant={"danger-outline"}
          />
        </div>

        <ToastContainer />
      </div>
    </div>
  );
};

export default MultiLabelOrderMerge;
