import React from "react";
import Typed from "react-typed";
import { useNavigate } from "react-router-dom";
import ButtonX from "./../../../components/ButtonX";
function Hero() {
  const navigate = useNavigate();
  return (
    <div className="text-white pt-24 bg-black">
      <div className="max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center">
        <p className="text-[#eb6d20] font-bold p-2">
          Streamline Your Orders, Enhance Productivity
        </p>
        <h1 className="md:text-7xl sm:text-6xl text-4xl font-bold md:py-6">
          Boost Your Efficiency
        </h1>
        <div className="flex justify-center items-center">
          <p className="md:text-5xl sm:text-4xl text-xl font-bold">
            Optimized Production of
          </p>
          <Typed
            className="md:text-5xl sm:text-4xl text-xl font-bold pl-2 text-[#eb6d20]"
            strings={[
              "T-Shirts",
              "Jewelry",
              "Wall Art",
              "Candles",
              "Clothing",
              "Stickers",
            ]}
            typeSpeed={80}
            backSpeed={140}
            loop
          />
        </div>
        <p className="md:text-2xl text-xl font-bold text-gray-500 p-2">
          Observe, analyze, optimize - revolutionize your production process
          with TegoMerge
        </p>
        <ButtonX name={"Get Started"} onClick={()=>{navigate('/auth')}} />


      </div>
    </div>
  );
}

export default Hero;
