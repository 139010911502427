import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 px-4 text-gray-800">
      <div className="max-w-2xl mx-auto">
        <a href="/" >
          <div className="bg-[#eb6d20] text-white p-4 rounded-t">
            <h1 className="text-2xl font-bold text-center">TegoMerge</h1>
          </div>
        </a>
        <div className="p-4 bg-white rounded-b shadow">
          <h1 className="text-2xl font-bold mb-4 text-eb6d20">
            Privacy Policy
          </h1>

          <h2 className="text-xl font-semibold mb-2 text-eb6d20">
            1. Introduction
          </h2>
          <p className="mb-4">
            TegoMerge ("we", "us", "our") is committed to protecting and
            respecting your privacy. This policy explains the types of personal
            information we collect, how we use it, who we share it with, and how
            we protect it.
          </p>

          <h2 className="text-xl font-semibold mb-2 text-eb6d20">
            2. Information We Collect
          </h2>
          <p className="mb-4">
            We only collect personal data voluntarily provided by users. This
            may include names, contact information, and any other personal
            details that users choose to provide. We do not collect personal
            data automatically or without the user's knowledge.
          </p>

          <h2 className="text-xl font-semibold mb-2 text-eb6d20">
            3. Use of Information
          </h2>
          <p className="mb-4">
            We use your personal data solely for the purposes for which it was
            provided, such as responding to inquiries, providing our services,
            and communicating with you.
          </p>

          <h2 className="text-xl font-semibold mb-2 text-eb6d20">
            4. Sharing of Information
          </h2>
          <p className="mb-4">
            We will not share your personal data with third parties without your
            express consent, except in circumstances where required by law or if
            necessary to provide our services.
          </p>

          <h2 className="text-xl font-semibold mb-2 text-eb6d20">
            5. Payment Information
          </h2>
          <p className="mb-4">
            We use Stripe to process payments. We do not store credit card
            details on our servers. Stripe's use of your personal information is
            governed by their own Privacy Policy.
          </p>

          <h2 className="text-xl font-semibold mb-2 text-eb6d20">
            6. Changes to Our Privacy Policy
          </h2>
          <p className="mb-4">
            We reserve the right to update our Privacy Policy at any time.
            Changes will be notified via a prominent notice on our website.
          </p>

          <h2 className="text-xl font-semibold mb-2 text-eb6d20">7. Contact</h2>
          <p className="mb-4">
            If you have questions or concerns about our Privacy Policy, please
            contact us directly. Email: melikhan.hosdogdu@gmail.com
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
