import React from "react";
import MergeImg from "../../../assets/merge.png";
import ButtonX from "./../../../components/ButtonX";
import { useNavigate } from "react-router-dom";
function Merge() {
  
  const navigate = useNavigate();
  return (
    <div name = 'merge' className="w-full bg-white pl-12 py-16 px-4">
      <div className=" max-w-[1240] mx-auto grid md:grid-cols-2">
        <div className="flex flex-col justify-center">
          <p className="text-[#eb6d20] font-bold ">TegoMerge </p>
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
            Merge Order and Shipping label
          </h1>
          <p>
            Don't waste time stapling Optimize your Etsy order handling with
            TegoMerge. We merge your order and shipping label PDFs into one
            unified document, boosting efficiency and accuracy in your
            production process. Reduce paperwork, eliminate errors, and spend
            more time on what truly matters - creating your unique products.
            Simplify and enhance your Etsy selling experience with TegoMerge
          </p>
          <ButtonX name={"Get Started"} onClick={()=>{navigate('/auth')}} />

        </div>
        <img className="w-[300px] mx-auto my-4" src={MergeImg} alt="/" />
      </div>
    </div>
  );
}

export default Merge;
