import { Link, useLocation, useNavigate } from "react-router-dom";
import { RoutesX } from "../utils/RoutesX";
import useAuthStore from "../stores/useAuthStore";

function Sidebar() {
  const location = useLocation();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const setToken = useAuthStore((state) => state.setToken);
  const setAuth = useAuthStore((state) => state.setAuth);
  const navigate = useNavigate();

  function logOut() {
    setToken();
    setAuth(false);

    localStorage.removeItem("token");
    navigate(location.pathname);
  }

  function routeManager(to, protectedRoute) {
    if (!protectedRoute) {
      return to;
    }
    if (!isAuthenticated) {
      return "/login";
    }
    return to;
  }

  return (
    <div className="w-64 bg-gray-700 h-screen p-4 flex flex-col justify-between">
      <nav>
        <ul>
          {RoutesX.map(({ to, text, protectedRoute }) => (
            <li className="mb-2" key={to}>
              <Link
                to={routeManager(to, protectedRoute)}
                className={`
                  text-white block px-4 py-2 rounded w-full
                  ${
                    location.pathname === to
                      ? "bg-gray-600"
                      : "hover:bg-gray-600"
                  }
                `}
              >
                {text}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <div className="flex flex-col justify-start items-start">
        {isAuthenticated ? (
          <Link
            to={location.pathname}
            className={`text-red-600 block py-4 px-4 rounded w-full hover:bg-gray-600`}
            onClick={logOut}
          >
            Log Out
          </Link>
        ) : null}
        <div className="text-gray-600 px-4">Verson 1.2.8</div>
      </div>
    </div>
  );
}

export default Sidebar;
