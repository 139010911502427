function dateFormat(params) {
  const date = new Date(params);
  // Subtract 5 hours from the current hours
  date.setHours(date.getHours() - 5);

  const month = date.getMonth() + 1; // getMonth() starts from 0 for January
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${month}-${day}-${year} ${hours}:${minutes}`;
}

function fileNameDateFormatter(params) {
  const date = new Date(params);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const month = monthNames[date.getMonth()]; // getMonth() starts from 0 for January
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `on ${month} ${day} at ${hours}-${minutes}`;
}

module.exports = {
  dateFormat,
  fileNameDateFormatter,
};
