import axios from "axios";

// Create an axios instance
const apiX = axios.create({
  baseURL: "https://62.72.1.114/api/v1",
});

// // Request interceptor for API calls
// apiX.interceptors.request.use(
//   async (config) => {
//     const token = localStorage.getItem("token");
//     if (token) {
//       config.headers = {
//         Authorization: `Bearer ${token}`,
//       };
//     }

//     console.log(config.params);
//     return config;
//   },
//   (error) => {
//     Promise.reject(error);
//   }
// );

// // Response interceptor for API calls
// apiX.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async function (error) {
//     const originalRequest = error.config;
//     // console.log("error", error.response);
//     if (error.response.status === 400) {
//       console.log("400");
//     }

//     const refreshToken = localStorage.getItem("refreshToken");
//     if (refreshToken) {
//       if (
//         error.response.status === 401 &&
//         originalRequest.url.includes("/refresh")
//       ) {
//         // If user sent refresh token and its get error 401 then remove token and refresh token from local storage and redirect to login page
//         localStorage.removeItem("token");
//         localStorage.removeItem("refreshToken");
//         window.location.href = "/auth";
//         return Promise.reject(error);
//       }

//       if (error.response.status === 403 && !originalRequest._retry) {
//         console.log("Refresh token expired");
//         originalRequest._retry = true;
//         return apiX.post("/user/refresh", { refreshToken }).then((res) => {
//           if (res.status === 200) {
//             console.log("Refresh token refreshed");
//             console.log("token" + res.data.token);
//             localStorage.setItem("token", res.data.token);
//             console.log("originalRequest");
//             if (originalRequest.data) {
//               originalRequest.data = JSON.parse(originalRequest.data);
//             }
//             console.log(originalRequest);

//             return apiX(originalRequest);
//           }
//         });
//       }
//       return Promise.reject(error);
//     }
//   }
// );

export default apiX;
