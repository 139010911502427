// import React from "react";
import LoadingX from "./../LoadingX";
// function ButtonX({ name, onClick, loading, width, incomingColor }) {
//   const color = incomingColor === undefined ? "eb6d20" : "000000";

//   const bg = `bg-[#${color}]`;
//   const border = `border-[#${color}]`;

//   console.log(bg)
//   console.log(incomingColor)
//   return (
//     <button
//       className={`
//       first-letter: ${bg} text-white border ${border}
//       hover:bg-transparent hover:text-[#eb6d20] hover:border hover:border-[#eb6d20]
//     ${width === undefined ? "w-[200px]" : width}
//     rounded-md font-medium my-6 mx-auto py-3`}
//       onClick={onClick}
//       disabled={loading}
//     >
//       {loading ? <LoadingX color={"text-[#eb6d20]"} /> : name}
//     </button>
//   );
// }

// export default ButtonX;

import PropTypes from "prop-types";

export const classNames = (...classes) => classes.filter(Boolean).join(" ");

const ButtonX = ({
  className,
  name,
  rounded,
  variant,
  disabled,
  loading,
  ...props
}) => (
  <button
    className={classNames(
      "px-4 py-2 cursor-pointer select-none [outline:none] shadow-md focus:ring-[1px] disabled:cursor-not-allowed  disabled:shadow-inner",
      rounded ? "rounded" : "",

      variant === "primary"
        ? `
        rounded-md font-medium my-6 mx-auto py-3
        w-[200px] bg-[#eb6d20] text-white border border-[#eb6d20] hover:bg-transparent hover:text-[#eb6d20] hover:border hover:border-[#eb6d20]`
        : "",
      variant === "secondary"
        ? "bg-slate-500 hover:bg-slate-400 focus:ring-slate-300 text-white"
        : "",
      variant === "success"
        ? "rounded-md font-medium my-4 bg-green-600 hover:bg-green-700 focus:ring-green-500 text-white"
        : "",
      variant === "danger"
        ? "bg-red-700 hover:bg-red-600 focus:ring-red-500 text-white"
        : "",
      variant === "warning"
        ? "bg-yellow-500 hover:bg-yellow-400 focus:ring-yellow-300 text-white"
        : "",
      variant === "info"
        ? "bg-cyan-700 hover:bg-cyan-600 focus:ring-cyan-500 text-white"
        : "",
      variant === "light"
        ? "bg-white hover:bg-gray-100 focus:ring-gray-300 text-gray-500"
        : "",
      variant === "dark"
        ? "bg-gray-700 hover:bg-gray-600 focus:ring-gray-500 text-white"
        : "",
      variant === "primary-outline"
        ? "text-blue-500 border-[1px] border-blue-500 hover:bg-blue-500 hover:text-white focus:ring-blue-500"
        : "",
      variant === "secondary-outline"
        ? "text-slate-500 border-[1px] border-slate-500 hover:bg-slate-500 hover:text-white focus:ring-slate-500"
        : "",
      variant === "success-outline"
        ? "text-green-700 border-[1px] border-green-700 hover:bg-green-700 hover:text-white focus:ring-green-500"
        : "",
      variant === "danger-outline"
        ? "rounded-md font-medium my-4  text-red-700 border-[1px] border-red-700 hover:bg-red-700 hover:text-white focus:ring-red-500"
        : "",
      variant === "warning-outline"
        ? "text-yellow-500 border-[1px] border-yellow-500 hover:bg-yellow-500 hover:text-white focus:ring-yellow-500"
        : "",
      variant === "info-outline"
        ? "text-cyan-700 border-[1px] border-cyan-700 hover:bg-cyan-700 hover:text-white focus:ring-cyan-500"
        : "",
      variant === "light-outline"
        ? "text-gray-400 border-[1px] border-gray-300 hover:bg-gray-100 focus:ring-gray-300"
        : "",
      variant === "dark-outline"
        ? "    rounded-md font-medium my-4  text-gray-700 border-[1px] border-gray-700 hover:bg-gray-700 hover:text-white focus:ring-gray-500"
        : "",
      className
    )}
    {...props}
    disabled={disabled || loading}
  >
    {loading ? <LoadingX color={"text-white"} /> : name}
  </button>
);

ButtonX.defaultProps = {
  rounded: true,
  variant: "primary",
};

ButtonX.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  rounded: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "light",
    "dark",
    "primary-outline",
    "secondary-outline",
    "success-outline",
    "danger-outline",
    "warning-outline",
    "info-outline",
    "light-outline",
    "dark-outline",
  ]),
};
export default ButtonX;
