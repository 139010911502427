import React from "react";

import Laptop from "../../../assets/laptop.jpg";
import ButtonX from "./../../../components/ButtonX";
import { useNavigate } from "react-router-dom";

function Analytics() {
  const navigate = useNavigate();
  return (
    <div name = 'insight' className="w-full bg-white py-16 px-4">
      <div className=" max-w-[1240] mx-auto grid md:grid-cols-2">
        <img className="w-[500px] mx-auto my-4" src={Laptop} alt="/" />
        <div className="flex flex-col justify-center">
          <div className="flex">

            <p className="text-[#eb6d20] font-bold ">TegoMerge Insights</p>
            <p className="text-[#eb6d20] font-extralight ml-3"> Comming soon</p>
          </div>
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
            Master Your Production with Real-Time Insights
          </h1>
          <p>
            With TegoMerge's analytics dashboard, navigate your production
            process like never before. Gain instantaneous visibility into all
            production stages using our unique QR code tracking. Monitor orders
            as they traverse different stations, evaluate individual user
            performance, and promptly identify any bottlenecks. Utilize
            data-driven insights to optimize your workflow, reduce
            inefficiencies, and enhance productivity. With TegoMerge, stay one
            step ahead and make your production process smarter and smoother
          </p>
          <ButtonX name={"Get Started"} onClick={()=>{navigate('/auth')}} />
        </div>
      </div>
    </div>
  );
}

export default Analytics;
