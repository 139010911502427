import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import {
  notifyExportLabelExist,
  notifLabelCountAndOrderCountIsNotEqual,
  notifyWarningByParameter,
} from "../utils/SnackBarUtils";

import { mergePDFs, splitPDF } from "../utils/PdfMergeUtils";

function FileUpload({
  item,
  index,
  removeFileUpload,
  onAddMergeValue,
  updateItemAtIndex,
}) {
  const [label, setLabel] = useState();
  const [order, setOrder] = useState();
  const [orderSizeList, setOrderSizeList] = useState([]);
  const [orderNumbers, setOrderNumbers] = useState([]);
  const [labelExportIndex, setLabelExportIndex] = useState([]);
  const [emptyOrderPageIndex, setEmptyOrderPageIndex] = useState([]);
  const [labelLoading, setLabelLoading] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);
  useEffect(() => {
    if (orderSizeList && orderNumbers) {
    }
    if (label && order && orderSizeList) {
      console.log("burada");
      try {
        handleMergeClick();
      } catch (error) {
        console.log("hata burada");
      }
      console.log("burada sonrasi");
    }
  }, [
    orderSizeList,
    orderNumbers,
    labelLoading,
    orderLoading,
    labelExportIndex,
    emptyOrderPageIndex,
  ]);
  const handleMergeClick = async () => {
    if (label && order && orderSizeList) {
      try {
        let resultOfMerge = await mergePDFs(
          label,
          order,
          orderSizeList,
          orderNumbers,
          item.labelFileName,
          item.orderFileName,
          labelExportIndex,
          emptyOrderPageIndex,
          true
        );

        if (typeof resultOfMerge === "boolean") {
          console.log("resultOfMerge is a boolean");
          if (resultOfMerge) {
            notifLabelCountAndOrderCountIsNotEqual();
            updateItemAtIndex("hasError", true, index);
          }
        } else {
          if (labelExportIndex.length > 0) {
            //! Burasi calismiyor
            let orderInfo;
            notifyExportLabelExist(orderInfo);
            console.log(orderNumbers);
            for (let index = 0; index < labelExportIndex.length; index++) {
              orderInfo = "";
              orderInfo =
                orderInfo +
                `  Order sequence: ${orderNumbers.indexOf(
                  labelExportIndex[index].orderNumber
                )}  - 
              Order Number: ${labelExportIndex[index].orderNumber}`;
              notifyWarningByParameter(orderInfo);
            }

            updateItemAtIndex("hasWarning", true, index);
          }
          onAddMergeValue(resultOfMerge, index);
          updateItemAtIndex("merged", true, index);
          updateItemAtIndex("hasError", false, index);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      console.error(
        `label : ${label} - order : ${order} -  orderSizeList : ${orderSizeList} `
      );
      console.error("Please select both PDFs before merging");
    }
  };
  const handleOrderFileChange = async (event) => {
    try {
      setOrderLoading(true);
      const file = event;

      // setOrderFileName(file.name);
      updateItemAtIndex("orderFileName", file.name, index);

      if (file) {
        const arrayBuffer = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsArrayBuffer(file);
        });
        let orderList = await splitPDF(arrayBuffer, true);
        let orderSizes = orderList[0];
        let orderNumbers = orderList[1];
        let emptyOrderPageIndexx = orderList[3];
        setOrderSizeList(orderSizes);
        setOrderNumbers(orderNumbers);
        setOrder(arrayBuffer);
        setEmptyOrderPageIndex(emptyOrderPageIndexx);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setOrderLoading(false);
    }
  };

  const handleLabelFileChange = async (event) => {
    try {
      setLabelExportIndex([]);
      setLabelLoading(true);
      const file = event;
      // setLabelFileName(file.name)
      updateItemAtIndex("labelFileName", file.name, index);

      if (file) {
        const result = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsArrayBuffer(file);
        });
        let orderList = await splitPDF(result, false);
        console.log(`${orderList[2]}`);
        setLabelExportIndex(orderList[2]);
        setLabel(result);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLabelLoading(false);
    }
  };
  const onDropOrder = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];
      handleOrderFileChange(file, index);
    },
    [handleOrderFileChange, index]
  );

  const onDropLabel = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];
      handleLabelFileChange(file, index);
    },
    [handleLabelFileChange, index]
  );

  const {
    getRootProps: getOrderRootProps,
    getInputProps: getOrderInputProps,
    isDragActive: isDragActiveOrder,
  } = useDropzone({
    onDrop: item.merged || item.hasWarning ? null : onDropOrder,
    noClick: item.merged || item.hasWarning,
    noKeyboard: item.merged || item.hasWarning,
    accept: ".pdf",
  });

  const {
    getRootProps: getLabelRootProps,
    getInputProps: getLabelInputProps,
    isDragActive: isDragActiveLabel,
  } = useDropzone({
    onDrop: item.merged || item.hasWarning ? null : onDropLabel,
    noClick: item.merged || item.hasWarning,
    noKeyboard: item.merged || item.hasWarning,
    accept: ".pdf",
  });
  const handleRemoveClick = () => {
    // To delete specific orders
    removeFileUpload(index);
  };

  return (
    <div className="item-center justify-center  bg-white m-4 rounded-lg ">
      <div className={`flex`}>
        <div
          className={` 
          text-2xl text-gray-600 font-bold pl-3 pt-3
        `}
        >
          <div>{index + 1}</div>
        </div>
        <div className="w-full">
          <div className="flex item-center justify-center w-full">
            <div {...getOrderRootProps()} className="flex space-x-2 w-full">
              <input {...getOrderInputProps()} style={{ display: "none" }} />
              <div
                className={`
              w-full h-52 flex justify-center items-center  
              cursor-pointer 
              rounded-lg 
              bg-[#f8f9fa]
              m-4
              text-xl text-center font-bold
              ${
                isDragActiveOrder
                  ? "bg-gray-500"
                  : item.merged || item.hasWarning
                  ? ""
                  : "hover:bg-[#f0f2f5] text-[#384a69]"
              }
              ${index % 2 === 1 ? "border-gray-600" : ""}
              ${
                item.merged
                  ? "border-green-600 opacity-50 cursor-not-allowed"
                  : ""
              }
              ${
                item.hasWarning
                  ? "border-yellow-600 opacity-50 cursor-not-allowed"
                  : ""
              }
              ${item.hasError ? "border-red-600" : ""}
              `}
              >
                {orderLoading
                  ? "Order loading ..."
                  : item.orderFileName
                  ? item.orderFileName
                  : "Upload Order / Drop Here"}
              </div>
            </div>

            <div
              {...getLabelRootProps()}
              className="flex items-center space-x-2 w-full"
            >
              <input {...getLabelInputProps()} style={{ display: "none" }} />
              <label
                className={`
                w-full h-52 flex justify-center items-center  
                cursor-pointer 
                rounded-lg 
                bg-[#f8f9fa]
                m-4
                text-xl text-center font-bold
              ${
                isDragActiveLabel
                  ? "bg-gray-500"
                  : item.merged || item.hasWarning
                  ? ""
                  : "hover:bg-[#f0f2f5] text-[#384a69]"
              }
              ${index % 2 === 1 ? "border-gray-600" : ""}
              ${
                item.merged
                  ? "border-green-600 opacity-50 cursor-not-allowed"
                  : ""
              }
              ${
                item.hasWarning
                  ? "border-yellow-600 opacity-50 cursor-not-allowed"
                  : ""
              }
              
              ${item.hasError ? "border-red-600" : ""}
              `}
              >
                {labelLoading
                  ? "Label loading ..."
                  : item.labelFileName
                  ? item.labelFileName
                  : "Upload Label / Drop Here"}
              </label>
            </div>
          </div>
          <div
            className={`
       flex justify-center items-center pt-1 pb-4
                ${item.merged ? "text-green-600" : ""}
                ${item.hasError ? "text-red-600" : ""}
                ${item.hasWarning ? "text-yellow-600" : ""}
        `}
          >
            {item.hasWarning
              ? "Merged, but you need to check by human eyes "
              : item.merged
              ? "Merged"
              : ""}
            {item.hasError
              ? "Can't merged, Orders and labels is not equal"
              : ""}
          </div>
        </div>

        {/* Delete Buton */}

        <div
          onClick={handleRemoveClick}
          className={`
        ml-3
        p-2
        hover:bg-red-700 hover:text-white
        text-red-600
        font-semibold
        flex items-center justify-center
        rounded-lg
        pr-4
        ${index % 2 === 1 ? "text-gray-600" : ""}
        `}
        >
          Delete
        </div>
      </div>
    </div>
  );
}

export default FileUpload;
