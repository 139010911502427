import React from "react";
import Form from "./components/Form";
function Auth() {
  return (
    <div className="flex w-full h-screen">
      <div className="w-full flex items-center justify-center md:w-1/2">
        <Form />
      </div>
      {
        <div className=" hidden relative md:flex h-full items-center w-1/2 justify-center bg-gray-200">
          <h1 className="w-full  font-bold text-[#eb6d20] text-center mx-auto pb-16 md:text-3xl  lg:text-4xl  xl:text-5xl 2xl:text-6xl ">
            TegoMerge..
          </h1>
          <div className="hidden relative md:flex h-full items-center w-full justify-center bg-gray-200">
            <div className="w-60 h-60 bg-gradient-to-tr from-[#ff6200] to-[#fc9353] rounded-full animate-spin" />
            <div className="w-full h-1/2 absolute bottom-0 bg-white/10 backdrop-blur-lg" />
          </div>
        </div>
      }
    </div>
  );
}

export default Auth;
