import { create } from "zustand";
import PaymentService from "../services/PaymentService";
const useUserStore = create((set) => ({
  name: "",
  role: "",
  stationId: "",
  stationName: "",
  subscriptionExpiredDate: null,
  setUser: (value) =>
    set(() => ({
      name: value.name,
      role: value.role,
      email: value.email,
      stationId: value.current_station_id,
      stationName: value.current_station_name,
      subscriptionExpiredDate: value.subscription_expired_date,
    })),
  isUserActive: async (getState) => {
    const { email } = getState();
    console.log(`email ${email}`);
    const response = await PaymentService.subscriptionStatus(email);
    console.log(response);
    return response.isSubscribed;
  },
}));

export default useUserStore;
