import api from "./api";

const AuthService = {
  register: async (registerData) => {
    try {
      const response = await api.post("/auth/merge_qr_register", registerData);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  login: async (loginData) => {
    try {
      const response = await api.post("/auth/login", loginData);
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  verify: async (email) => {
    try {
      if (!email || email === "") {
        return false;
      }

      // const response = await api.post("/auth/verify");
      var list = [
        "burakyerlikayaaa@gmail.com",
        "skydesignsandall@gmail.com",
        "huzeyfeaslan@gmail.com",
        "example@mail.com",
      ];
      if (list.includes(email)) {
        return true;
      }

      return false;
      //  response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  refreshToken: async () => {
    try {
      const response = await api.post("/auth/refresh");
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

export default AuthService;
