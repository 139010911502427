import Merge from "../pages/merge";
import Payment from "../pages/payment";
import PrivacyPolicy from "../pages/legal/privacyPolicy";
import TermAndCondition from "../pages/legal/termAndCondition";

export const RoutesX = [
  // {
  //   to: "/",
  //   text: "Label Order Merge",
  //   component: LabelOrderMerge,
  //   protectedRoute: false,
  // },
  {
    to: "/merge",
    text: "Merge",
    component: Merge,
    protectedRoute: true,
  },
  {
    to: "/payment",
    text: "Payment",
    component: Payment,
    protectedRoute: true,
  },
  {
    to: "/privact_policy",
    text: "Privacy Policy",
    component: PrivacyPolicy,
    protectedRoute: false,
  },
  {
    to: "/term_and_condition",
    text: "Term And Condition",
    component: TermAndCondition,
    protectedRoute: false,
  },
];
