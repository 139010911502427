import React, { useState, useEffect } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Link } from "react-scroll";
import ButtonX from "../../../components/ButtonX";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../../stores/useAuthStore";

import AuthService from "./../../../services/AuthService";
function Navbar() {
  const [nav, setNav] = useState(false);
  const navigate = useNavigate();
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const setAuth = useAuthStore((state) => state.setAuth);

  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const checkAuthenticated = async () => {
    try {
      const response = await AuthService.verify(email);
      response === true ? setAuth(true) : setAuth(false);
    } catch (err) {
      console.error(err.message);
    }
  };
  const handleNav = () => {
    setNav(!nav);
  };
  useEffect(() => {
    checkAuthenticated();
    console.log("protectedXX");
    if (!isAuthenticated) {
      navigate("/", { replace: true });
    }
  }, [isAuthenticated, navigate]);
  return (
    <div
      className="
    
    bg-black
    "
    >
      <div
        className="
    flex 
    justify-between
    items-center
    text-white
    h-24
    w-full
    max-w-[1240px]
    mx-auto
    px-4
    "
      >
        <h1 className="w-full text-3xl font-bold text-[#eb6d20] ">TegoMerge</h1>
        <ul className="hidden md:flex items-center">
          <li className=" p-4 hover:cursor-pointer">
            <Link to="home" smooth={true} duration={500}>
              Home
            </Link>
          </li>
          <li className="p-4 hover:cursor-pointer">
            <Link to="merge" smooth={true} duration={500}>
              Merge
            </Link>
          </li>
          <li className="p-4 hover:cursor-pointer">
            <Link to="insight" smooth={true} duration={500}>
              Insight
            </Link>
          </li>
          <li className="p-4 hover:cursor-pointer">
            <Link to="pricing" smooth={true} duration={500}>
              Pricing
            </Link>
          </li>
          <li>
            <ButtonX
              name={isAuthenticated ? "Dasboard" : "Get Started"}
              onClick={() => {
                if (isAuthenticated) {
                  navigate("/merge");
                } else {
                  navigate("/auth");
                }
              }}
            />
          </li>
        </ul>
        <div
          onClick={handleNav}
          className="block md:hidden hover:cursor-pointer"
        >
          {nav ? <AiOutlineClose /> : <AiOutlineMenu />}
        </div>
        <div
          className={
            nav
              ? `fixed left-0 top-0 w-[60%] h-full
          border-r border-r-gray-900
          bg-[#000300]
          ease-in-out duration-500
          `
              : `fixed left-[-100%] top-0
            ease-in-out duration-700
            `
          }
        >
          <h1 className="w-full text-3xl font-bold text-[#eb6d20] m-4">
            TegoMerge
          </h1>

          <ul className="uppercase">
            <li className="p-4 border-b border-gray-600">
              <Link to="home" smooth={true} duration={500}>
                Home
              </Link>
            </li>
            <li className="p-4 border-b border-gray-600">
              <Link to="merge" smooth={true} duration={500}>
                Merge
              </Link>
            </li>
            <li className="p-4 border-b border-gray-600">
              <Link to="insight" smooth={true} duration={500}>
                Insight
              </Link>
            </li>
            <li className="p-4 border-b border-gray-600">
              <Link to="pricing" smooth={true} duration={500}>
                Pricing
              </Link>
            </li>
            <li className="pl-4">
              <ButtonX
                name={isAuthenticated ? "Dasboard" : "Get Started"}
                onClick={() => {
                  if (isAuthenticated) {
                    navigate("/merge");
                  } else {
                    navigate("/auth");
                  }
                }}
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
