import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

import PaymentService from "../../../../services/PaymentService";
import ButtonX from "../../../../components/ButtonX";
import useUserStore from "../../../../stores/useUserStore";
import { useNavigate } from "react-router-dom";

const PaymentBody = () => {
  const stripe = useStripe();
  const elements = useElements();

  const { name, email } = useUserStore();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const createSubscription = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const paymentMethod = await stripe.createPaymentMethod({
        card: elements.getElement("card"),
        type: "card",
      });

      const arg = {
        name,
        email,
        paymentMethod: paymentMethod.paymentMethod.id,
      };

      const response = await PaymentService.createCheckoutSession(arg);

      console.log(response);
      console.log(response.status);

      const confirm = await stripe.confirmCardPayment(response.clientSecret);
      if (confirm.error) return alert("Payment unsuccessful!");
      alert("Payment Successful! Subscription active.");
      navigate("/merge");
    } catch (err) {
      console.error(err);
      alert("Payment failed! " + err.message);
    }

    setLoading(false);
  };

  return (
    <div className="w-4/5">
      <h1 className="text-5xl font-semibold">Final Step to Efficiency</h1>
      <p className="font-medium text-lg text-gray-500 mt-4 pb-10">
        Embrace the full power of TegoMerge for only $9.99! Get unlimited access
        to merge operations and user accounts. Accelerate efficiency, capture
        comprehensive insights, and foster growth. It's time to sync your way to
        success!
      </p>

      <div className="bg-white p-4">
        <CardElement />
      </div>

      <ButtonX
        name={"Subscribe for $9.99"}
        loading={loading}
        onClick={(e) => {
          createSubscription(e);
        }}
      />
    </div>
  );
};

export default PaymentBody;
