import api from './_api';


const UserService = {
  getMe: async () => {
    try {
      const response = await api.get('/user/detail');
      return response.data;
    } catch (error) {
        console.error(error); // log the error
        // Here, you can decide what to do based on the error
        // For instance, if it's an authorization error, you can redirect the user to a login page
        if (error.response && error.response.status === 401) {
          // redirect to login page
        }
        // You can also decide to rethrow the error if you want to handle it in the calling code
        throw error;
    }
  },
  updateProfile: async (userData) => {
    try {
      const response = await api.put('/profile', userData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  // Add other user-related endpoints here.
};

export default UserService;


// import React, { useEffect, useState } from 'react';
// import UserService from '../services/UserService';

// const ProfilePage = () => {
//   const [profile, setProfile] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//         try {
//           const profileData = await UserService.getProfile();
//           setProfile(profileData);
//         } catch (error) {
//           setError(error.message); // set error message to state
//         }
//       };
  
//       fetchData();
//   }, []);

//   // Your component render
//   return (
//     <div>
//       {profile && (
//         <div>
//           <h1>{profile.name}</h1>
//           <p>{profile.role}</p>
//           <p>{profile.current_station}</p>
//         </div>
//       )}
//     </div>
//   );
// };


