import { degrees, PDFDocument, rgb } from "pdf-lib";
import { saveAs } from "file-saver";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import { getDocument } from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { fileNameDateFormatter } from "./DateTimeParser";

export async function mergeMultiPdfsAndDownload(pdfDocuments, mergeCount) {
  console.log(pdfDocuments.length);
  if (pdfDocuments.length === 0) {
    return;
  }
  const mergedPdf = await PDFDocument.create();

  for (let pdfDoc of pdfDocuments) {
    const pages = await pdfDoc.getPages();
    for (let i = 0; i < pages.length; i++) {
      const [copiedPage] = await mergedPdf.copyPages(pdfDoc, [i]);
      mergedPdf.addPage(copiedPage);
    }
  }
  // Save the merged PDF
  const mergedPdfArrayBuffer = await mergedPdf.save();

  // if (isPrint) {
  //   const base64_arraybuffer = async (data) => {
  //     // Use a FileReader to generate a base64 data URI
  //     const base64url = await new Promise((r) => {
  //       const reader = new FileReader();
  //       reader.onload = () => r(reader.result);
  //       reader.readAsDataURL(new Blob([data]));
  //     });

  //     return base64url.substring(base64url.indexOf(",") + 1);
  //   };

  //   const base64X = await base64_arraybuffer(mergedPdfArrayBuffer);

  //   print({ printable: base64X, type: "pdf", base64: true });
  // }

  // Create a blob from the array buffer
  const blob = new Blob([mergedPdfArrayBuffer], { type: "application/pdf" });

  let fileName = "";
  const time = fileNameDateFormatter(Date.now());
  if (pdfDocuments.length === 1) {
    // Save the file to the disk using FileSaver
    fileName = `${mergeCount[0].orderFileName.split(".")[0]} - ${
      mergeCount[0].labelFileName.split(".")[0]
    } merged ${time}.pdf`;
  } else {
    // Save the file to the disk using FileSaver
    fileName = `${pdfDocuments.length} files merged ${time}.pdf`;
  }
  saveAs(blob, fileName);
}

export async function extractTextFromPdf(pdf) {
  let textContent = "";

  for (let i = 1; i <= pdf.numPages; i++) {
    const page = await pdf.getPage(i);
    const content = await page.getTextContent();

    textContent += content.items
      .map((item) => {
        console.log(item.str);
        return item.str;
      })
      .join(" ");

    console.log(textContent);
  }

  return textContent;
}

export async function mergePDFs(
  label,
  order,
  orderSizeList,
  orderNumbers,
  labelFileName,
  orderFileName,
  labelExportIndex,
  emptyOrderPageIndex,
  isMultiple
) {
  // Load the second PDF
  const orderDocTmp = await PDFDocument.load(order);
  // Flatten the order PDF and save it
  const form = orderDocTmp.getForm();
  form.flatten();
  const flattenedOrderBytes = await orderDocTmp.save();
  // Load the first PDF
  const labelDoc = await PDFDocument.load(label);

  // Load the flattened order PDF
  const orderDoc = await PDFDocument.load(flattenedOrderBytes);

  // Create a new PDFDocument
  const mergedPdf = await PDFDocument.create();

  const labelList = labelDoc.getPages();

  if (orderNumbers.length !== labelList.length) {
    // If order count and label count is not equal merge has canceled and return true
    return true;
  }

  if (labelExportIndex.length > 0) {
    for (let index = 0; index < labelExportIndex.length; index++) {
      const elementToMove = labelList[labelExportIndex[index].exportLabelIndex];
      // order number
      const indexToMove = orderNumbers.indexOf(
        labelExportIndex[index].orderNumber
      );

      // Remove the element from its current position
      const currentIndex = labelList.indexOf(elementToMove);
      labelList.splice(currentIndex, 1);

      // Insert the element at the new position
      labelList.splice(indexToMove, 0, elementToMove);
    }
  }

  let orderStartingIndex = -1;

  for (
    let labelPageIndex = 0;
    labelPageIndex < labelList.length;
    labelPageIndex++
  ) {
    orderStartingIndex++;
    for (let index = 0; index < emptyOrderPageIndex.length; index++) {
      const element = emptyOrderPageIndex[index];
      // Passing empty files
      if (element === orderStartingIndex) {
        orderStartingIndex++;
      }
    }
    const labelFirstPage = labelList[labelPageIndex];
    const orderFirstPage = orderDoc.getPages()[orderStartingIndex];

    const scaleRate = 0.78;
    const addLeftSpace = parseFloat(localStorage.getItem("leftSpace") ?? 0);

    // Create the first page
    const page = mergedPdf.addPage([600, 825]);
    const rightSideOfPaper = await mergedPdf.embedPage(labelFirstPage);
    const leftSideOfPaper = await mergedPdf.embedPage(orderFirstPage);
    const leftSideOfPaperSize = leftSideOfPaper.width;

    page.drawPage(rightSideOfPaper, {
      x: 500,
      y: leftSideOfPaperSize - 100 + addLeftSpace,
      rotate: degrees(90),
    });

    // Embed the first page of the second PDF on the new page
    page.drawPage(leftSideOfPaper, {
      x: 615,
      y: 20,
      width: leftSideOfPaper.width * scaleRate,
      height: leftSideOfPaper.height * scaleRate,
      rotate: degrees(90),
    });
    page.drawRectangle({
      x: 0,
      y: 425,
      width: 600,
      height: 85,
      color: rgb(1, 1, 1),
    });

    page.setRotation(degrees(90));
    const scaleRateX = 0.8; // This rate is for second and following pages

    for (let index = 1; index < orderSizeList[labelPageIndex]; index++) {
      // Create the following page
      const pagex = mergedPdf.addPage([600, 825]);
      pagex.drawText("Order # " + orderNumbers[labelPageIndex], {
        x: 30,
        y: 320,
        size: 11,
        rotate: degrees(90),
        color: rgb(0, 0, 0),
      });

      // Embed the following page of the first PDF on the new page
      let rightSideOfPaper;
      let leftSideOfPaper;
      let rightSideEmpty = false;

      if (index % 2 === 0) {
        // For loop add page every loop, but don't need to odd number page
        mergedPdf.removePage(mergedPdf.getPageCount() - 1);
        continue;
      } else {
        orderStartingIndex++;
        const orderEvenPage = orderDoc.getPages()[orderStartingIndex];
        rightSideOfPaper = await mergedPdf.embedPage(orderEvenPage);
        if (index !== orderSizeList[labelPageIndex] - 1) {
          orderStartingIndex++;
          const orderOddPage = orderDoc.getPages()[orderStartingIndex];
          leftSideOfPaper = await mergedPdf.embedPage(orderOddPage);
        } else {
          rightSideEmpty = true;
        }
      }

      pagex.drawPage(rightSideOfPaper, {
        x: 630,
        y: -80,
        width: rightSideOfPaper.width * scaleRateX,
        height: rightSideOfPaper.height * scaleRateX,
        rotate: degrees(90),
      });
      if (!rightSideEmpty) {
        pagex.drawPage(leftSideOfPaper, {
          x: 630,
          y: rightSideOfPaper.width * scaleRateX - 150,
          width: leftSideOfPaper.width * scaleRateX,
          height: leftSideOfPaper.height * scaleRateX,
          rotate: degrees(90),
        });
      } else {
      }
      pagex.setRotation(degrees(90));
    }
  }
  if (isMultiple) {
    return mergedPdf;
  }

  // Save the merged PDF
  const mergedPdfArrayBuffer = await mergedPdf.save({ useObjectStreams: true });

  // Create a blob from the array buffer
  const blob = new Blob([mergedPdfArrayBuffer], { type: "application/pdf" });

  // Save the file to the disk using FileSaver
  const fileName =
    labelFileName.split(".")[0] +
    " - " +
    orderFileName.split(".")[0] +
    " merged.pdf";
  saveAs(blob, fileName);
}

export async function createSinglePagePDF(originalDoc, pageIndex) {
  const pdfDoc = await PDFDocument.create();
  const [page] = await pdfDoc.copyPages(originalDoc, [pageIndex]);
  pdfDoc.addPage(page);
  const pdfBytes = await pdfDoc.save();
  return pdfBytes;
}

// Function to split a PDF into separate PDFs for each page
export async function splitPDF(pdfBuffer, isOrder) {
  const pdfDoc = await PDFDocument.load(pdfBuffer);
  const numPages = pdfDoc.getPageCount();
  const orderNumbers = [];
  const listInteger = [];
  const labelExportIndex = [];
  const emptyOrderPageIndex = [];
  for (let i = 0; i < numPages; i++) {
    const pageBuffer = await createSinglePagePDF(pdfDoc, i);
    pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

    const pdf = await getDocument({ data: pageBuffer }).promise;

    let textPdf = await extractTextFromPdf(pdf);
    if (textPdf.includes("Order #")) {
      const orderNumberMatch = textPdf.match(/Order #(\d+)/);
      const orderNumberMatchLabel = textPdf.match(/Order # (\d+)/);
      if (orderNumberMatch) {
        const orderNumber = orderNumberMatch[1];
        // Only add the number if it doesn't already exist in the list
        if (!orderNumbers.includes(orderNumber)) {
          orderNumbers.push(orderNumber);
        }
      } else {
        console.log("No order number found");
      }

      if (orderNumberMatchLabel) {
        const orderNumber = orderNumberMatchLabel[1];

        labelExportIndex.push({
          orderNumber: orderNumber,
          exportLabelIndex: i,
        });
        // Only add the number if it doesn't already exist in the list
        // if (!orderNumbers.includes(orderNumber)) {
        //   orderNumbers.push(orderNumber);
        // }
      }

      listInteger.push(1);
    } else {
      if (isOrder && textPdf.trim().startsWith("Do the green thing")) {
        emptyOrderPageIndex.push(i);
      } else {
        listInteger[listInteger.length - 1] =
          listInteger[listInteger.length - 1] + 1;
      }
    }
  }
  return [listInteger, orderNumbers, labelExportIndex, emptyOrderPageIndex];
}
