import React from "react";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Analytics from './components/Analytics'
import Merge from './components/Merge'
import Newsletter from "./components/Newsletter";
import Cards from "./components/Pricing";
import Footer from "./components/Footer";
function Landing() {
  return (
    <div >
      <Navbar/>
      <Hero/>
      <Merge/>
      <Analytics/>
      <Newsletter/>
      <Cards/>
      <Footer/>
    </div>
  );
}

export default Landing;
