import React from "react";
import MultiLabelOrderMerge from "./components/MultiLabelOrderMerge";
import Navbar from "./components/Navbar";
function Merge() {
  return (
    <div>
      <Navbar />
      <MultiLabelOrderMerge />
    </div>
  );
}

export default Merge;
