import { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import PaymentBody from "./components/body";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import PaymentService from "../../services/PaymentService";
function Payment() {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const navigate = useNavigate();

  const getConfigUse = async () => {
    const publishableKey = await PaymentService.getConfig();
    setStripePromise(loadStripe(publishableKey["publishableKey"]));
  };

  const paymentIntent = async () => {
    const response = await PaymentService.createPaymentIntent();
    var { clientSecret } = response;
    setClientSecret(clientSecret);
  };
  useEffect(() => {
    getConfigUse();
  }, []);

  useEffect(() => {
    paymentIntent();
  }, []);

  return (
    <div className="flex w-full h-screen">
      <div className="w-full flex items-center justify-center md:w-1/2">
        {clientSecret && stripePromise && (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <PaymentBody />
          </Elements>
        )}
      </div>
      {
        <div className=" hover:cursor-pointer hidden relative md:flex h-full items-center w-1/2 justify-center bg-gray-200">
          <h1
            onClick={() => {
              navigate("/");
            }}
            className="w-full  font-bold text-[#eb6d20] text-center mx-auto pb-16 md:text-3xl  lg:text-4xl  xl:text-5xl 2xl:text-6xl "
          >
            TegoMerge
          </h1>
          <div className="hidden relative md:flex h-full items-center w-full justify-center bg-gray-200">
            <div className="w-60 h-60 bg-gradient-to-tr from-[#ff6200] to-[#fc9353] rounded-full animate-spin" />
            <div className="w-full h-1/2 absolute bottom-0 bg-white/10 backdrop-blur-lg" />
          </div>
        </div>
      }
    </div>
  );
}

export default Payment;
