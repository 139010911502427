import React from 'react';

const TermsAndConditions = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 px-4 text-gray-800">
            <div className="max-w-2xl mx-auto">
             <a href="/">
             <div className="bg-[#eb6d20] text-white p-4 rounded-t">
                    <h1 className="text-2xl font-bold text-center">TegoMerge</h1>
                </div>
             </a>
                <div className="p-4 bg-white rounded-b shadow">
                    <h1 className="text-2xl font-bold mb-4 text-eb6d20">Terms & Conditions</h1>

                    <h2 className="text-xl font-semibold mb-2 text-eb6d20">1. Introduction</h2>
                    <p className="mb-4">These Terms & Conditions govern your use of our services. By using our services, you agree to abide by these Terms & Conditions.</p>

                    <h2 className="text-xl font-semibold mb-2 text-eb6d20">2. Payments</h2>
                    <p className="mb-4">Payments made to TegoMerge are final and non-refundable, unless otherwise stated in a specific service agreement.</p>

                    <h2 className="text-xl font-semibold mb-2 text-eb6d20">3. Subscriptions</h2>
                    <p className="mb-4">You may cancel your subscription at any time by contacting us directly. However, the cancellation will only take effect from the next billing cycle and previous payments are non-refundable.</p>

                    <h2 className="text-xl font-semibold mb-2 text-eb6d20">4. Use of Our Services</h2>
                    <p className="mb-4">You agree to use our services in accordance with all applicable laws and not to use them for unlawful purposes.</p>

                    <h2 className="text-xl font-semibold mb-2 text-eb6d20">5. Liability</h2>
                    <p className="mb-4">Under no circumstances will we be liable for any direct, indirect, or consequential loss or damage arising from the use of our services.</p>

                    <h2 className="text-xl font-semibold mb-2 text-eb6d20">6. Changes to Terms & Conditions</h2>
                    <p className="mb-4">We reserve the right to change these Terms & Conditions at any time. We will provide notice of changes on our website.</p>

                    <h2 className="text-xl font-semibold mb-2 text-eb6d20">7. Contact</h2>
                    <p className="mb-4">If you have any questions about these Terms & Conditions, please contact us directly. Email: melikhan.hosdogdu@gmail.com</p>

                    <h2 className="text-xl font-semibold mb-2 text-eb6d20">8. Company Information</h2>
                    <p className="mb-4">TegoMerge is a company registered in Turkey, with registered office at TegoMerge, Istanbul, Turkey.</p>
                </div>
            </div>
        </div>
    );
};

export default TermsAndConditions;
